$main = $('.js-main');
$menu = $('#menu');
$menuIcon = $('.mobile-menu-icon');
// $section = $('.section-js');
$mobileMenu = $('.mobile-menu');
$header = $('#header');

function faqs() {


  var $faq = $('.faq')

  $faq.each(function(index, element) {
    $(this).on('click', function(e) {

      $(this).toggleClass('open');
      $(this).toggleClass('bg-' + active_color);
      $(this).toggleClass('bg-' + bg_color);
      $(this).toggleClass('text-' + text_color);
      $(this).toggleClass('text-' + active_text);

    });
  });

}

faqs();


$(document).ready(function() {

  $('a[href^="tel:"]').addClass('phone');
  $('a[href^="mailto:"]').addClass('email')

  $('.email').each(function(index, element) {
    $(this).on('click', function(e) {
      fathom.trackGoal('WNWMOAZB', 0);
    });
  })

  $('.phone').each(function(index, element) {
    $(this).on('click', function(e) {
      fathom.trackGoal('P5CAUZJU', 0);
    });
  })

  $('.social').each(function(index, element) {
    $(this).on('click', function(e) {
      fathom.trackGoal('1VZN6ZTM', 0);
    });
  })



})

$(document).ready(function() {
  $('a').each(function() {

    // if (!$(this).is('.email, .phone') || (!$(this).attr('target') == '_blank')) {

    if (!($(this).attr('target') == '_blank' || $(this).is('.email, .phone'))) {

      $(this).on('click', function(e) {


        console.log('worked');

        var href = $(this).attr("href");

        if (href == "#0") {
          return false;
        }

        $('body').removeClass('loaded').addClass('clicked');

        var href = $(this).attr("href");

        setTimeout(function() {
          window.location = href;
        }, 1000);


      })

    }

  })

})




function menu() {

  $menuIcon.on('click', function(e) {
    $($header).toggleClass("open");
    $($mobileMenu).toggleClass("open");
    $($main).toggleClass("menu-open");
    $(nav).removeClass("hide");



    // $menuIcon.toggleClass("open");
    // $main.toggleClass("exit-animation");

  });
}

menu();



function animations() {

  var $section = $('.a-section')

  $section.each(function(index, element) {
    var wrapper = $(this);
    var elementWatcher = scrollMonitor.create(wrapper, {
      top: -250
    });

    if ($(this).hasClass('full-reveal')) {

      elementWatcher.fullyEnterViewport(function() {
        wrapper.addClass('start-animation');
        wrapper.removeClass('opacity-0');
      });


    } else {

      elementWatcher.enterViewport(function() {
        wrapper.addClass('start-animation');
        wrapper.removeClass('opacity-0');


      });

    }
  });

}

animations();

function parallax() {


  $(window).scroll(function() {
    var scrollTop = $(window).scrollTop(),
      logo = $('.logo-bg'),
      logo_offset = logo.offset()




    var $parallax_container = $('.parallax_container');

    $parallax_container.each(function() {

      var container_offset = $(this).offset(),
        object_offset = $(this).find('.parallax').offset(),
        h_start = scrollTop - container_offset.top,
        v_start = scrollTop;


      var left = h_start / -10 + 'px';
      var right = h_start / 10 + 'px';
      var top = v_start / 8 + 'px';
      var bottom = v_start / -8 + 'px';
      // var scale = 100 + (v_start / 25) + '%'

      var scale = 1 + (v_start * .0001);



      $(this).find('.parallax-left').css('transform', 'translate3d(' + left + ', 0 ,0)');
      $(this).find('.parallax-right').css('transform', 'translate3d(' + right + ', 0 ,0)');
      $(this).find('.parallax-top').css('transform', 'translate3d(0, ' + top + ' ,0)');
      $(this).find('.parallax-bottom').css({
        transform: 'translate3d(0, ' + bottom + ' , 0)'
      });
      $(this).find('.parallax-scale').css({
        transform: 'scale(' + scale + ')'
      });
      // console.log(scale);

    })
  })
}

parallax();


function carousel() {

  $(document).ready(function() {
    $('.owl-carousel.gallery').owlCarousel({
      autoWidth: true,
      responsiveClass: true,
      dots: false,
      nav: false,
      loop: false,



      responsive: {
        0: {
          // items: 2,
        },
        600: {
          // items: 2,
        },
        1000: {}
      }
    })
  });

  $(document).ready(function() {
    $('.owl-carousel.reasons').owlCarousel({
      autoWidth: true,
      responsiveClass: true,
      dots: true,
      nav: false,
      loop: false,

      responsive: {
        0: {

          // items: 2,
        },
        600: {
          // items: 2,
        },
        1000: {}
      }
    })
  });

  $(document).ready(function() {
    $('.owl-carousel.full-carousel').owlCarousel({
      autoWidth: false,
      responsiveClass: true,
      dots: true,
      dotsData: true,
      nav: true,
      animateOut: 'fade-in-from-top',
      animateOut: 'fade-out',

      loop: true,
      dotsSpeed: '250',
      autoplayHoverPause: true,
      autoplay: false,


      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 1,
          nav: true
        },
        1000: {
          items: 1,
          nav: true,
          dots: true,
        }
      }
    });
  });

}

carousel();

function illustration() {

  if (typeof containers !== 'undefined') {

    $.each(containers, function(index, element) {

      // console.log($(json_url).eq(index));

      json = $(json_url)[index]


      var animation = lottie.loadAnimation({

        container: document.getElementById(element),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: json // the path to the animation json
      })


    })

  }


}

illustration();

// $(document).ready(function() {
//             $(".owl-item.active").first().addClass('true');
//         });

// $(".owl-item.active").on('event', function() {
//   // $('#myDiv').addClass('submission-ok').trigger('classChange');
//
//   $(".owl-item.active").first().addClass('true');
//
// });

// $( ".owl-item.active" ).first().change(function() {
//   $(".owl-item.active").first().addClass('true');
//
// });

$(document).ready(function() {

  $('.owl-item.active').first().addClass('true')


  $('.owl-carousel').on("translated.owl.carousel", $(".owl-item"), function(event) {
    /*** code here ***/

    $image_title = $(this).find('.owl-item.active').first().find('.slider-image').data('image');
    // console.log($image_title);
    $slide = $('.owl-item');
    $slide.removeClass('true')

    $(this).find('.owl-item.active').first().addClass('true');
    $('.test-quote').addClass('hidden');
    $('.test-quote-wrapper').find('.' + $image_title + '-quote').removeClass('hidden');




    // if ($slide.hasClass('active')) {
    //   $('.owl-item.active').removeClass('true')
    //
    //   $('.owl-item.active').eq(-1).addClass('active true')
    //
    // }

    // $slide.each(function(element) {
    //
    //   console.log($(this));
    //
    //   if ($(this).hasClass('active')) {
    //
    //     $('.owl-item').addClass('true');
    //
    //
    //
    //   }
    //
    //   else{
    //
    //     $('.owl-item').removeClass('true')
    //
    //
    //   }


  })

})


//
//     if ($('.owl-item').hasClass('active') && $('.owl-item.active').first()) {
//
//
//         $('.owl-item').addClass('true');
//         console.log('FUCK OFFFFFF');
//
// }
//       else{
//
//         $('.owl-item').removeClass('true')
//
//
//       }


// });

// in another js file, far, far away
// $('#myDiv').on('classChange', function() {
//      // do stuff
// });


// console.log(animation_load);
//
// function nav() {
//
//   var prev = 0;
//   var $window = $(window);
//
//   $(document).ready(function() {
//
//     $window.on('scroll', function() {
//
//       if (!$($main).hasClass("menu-open")) {
//
//         var scrollTop = $window.scrollTop();
//
//         if (scrollTop > 100) {
//           $header.addClass('scrolled-menu');
//           $header.find('#main-nav').removeClass(' ');
//           $header.find('#main-nav').addClass(' ');
//           // $header.find('.menu-wrapper').removeClass('lg:col-span-6 ');
//           // $header.find('.menu-wrapper').addClass('lg:col-span-11');
//
//           // $header.toggleClass('scrolled-menu', scrollTop > prev);
//           // prev = scrollTop;
//
//           console.log(scrollTop);
//
//
//         }else{
//
//           $header.removeClass('scrolled-menu');
//           $header.find('#main-nav').removeClass('py-2  bg-cream-100');
//           $header.find('#main-nav').addClass('  sub-section-p-t ');
//           // $header.find('.menu-wrapper').addClass('lg:col-span-6 ');
//           // $header.find('.menu-wrapper').removeClass('lg:col-span-11 ');
//
//
//
//
//         }
//
//       }
//
//
//     })
//
//     if ($header.hasClass('open')) {
//
//       $('body').addClass('overflow-hidden max-h-screen');
//
//     }else{
//       $('body').removeClass('overflow-hidden max-h-screen');
//
//     }
//
//   })
//
// }
// nav();

function reasons() {

  var $filter = $('.filter')

  $filter.each(function(index, element) {

    $(this).on('click', function() {

      $reason_title = $(this).data('filter');

      // console.log($reason_title);

      $('.reason-body').not(('.' + $reason_title + '-body')).addClass('hidden');
      $('.reason-body.' + $reason_title + '-body').removeClass('hidden');

      $('.filter').removeClass('filtered')
      $(this).addClass('filtered')
    });

  })
}

reasons();



// ###################### COMPOUND INTEREST ######################

function number_format(number, decimals, dec_point, thousands_sep) {
  // *     example: number_format(1234.56, 2, ',', ' ');
  // *     return: '1 234,56'
  number = (number + '').replace(',', '').replace(' ', '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function(n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}


//   var myLineChart = new Chart(ctx, {
//     type: 'line',
//     data: data,
//     options: options
// });

$(document).ready(function() {

  if ($("#myChart").length > 0) {
    //it doesn't exist


    var ctx = document.getElementById('myChart').getContext('2d');


    investment = $('#investment').val();
    monthly = $('#monthly').val();;
    interest = 0.07;
    years = parseInt($('#years').val());
    var currentTime = new Date();
    var current_year = parseInt(currentTime.getFullYear())
    var final_year = (current_year + years);
    increments = 5;

    var labelLength = 6;
    var labels = [];
    var i = 0;

    for (i = 0; i != labelLength; i++) {
      labels.push(current_year + (i * (years / increments)))
    }


    var amounts = [];
    var i = 0;

    for (i = 0; i != labelLength; i++) {

      amount = investment;
      base = (1 + (interest / 1));
      exponent = (1 * (i * (years / increments)));
      compiled = Math.pow(base, exponent)
      principal = compiled * investment



      future = (monthly*12) * ((compiled - 1 )/(interest / 1))

      final = principal + future

      amounts.push(final)

      ticks = [Math.round(amounts[5] + amounts[2]), 0];



    }



    var myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: '$',
          data: amounts,
          pointBackgroundColor: '#d36866',
          pointBorderWidth: 0,
          pointHoverBackgroundColor: '#d36866',
          pointRadius: [8, 0, 0, 0, 0, 8],
          pointHoverRadius: [8, 0, 0, 0, 0, 8],
          pointHoverBorderColor: '#d36866',
          pointHoverBorderWidth: 0,
          backgroundColor: [
            'rgba(211,104,102,0.25)',
            // 'rgba(54, 162, 235, 0.2)',
            // 'rgba(255, 206, 86, 0.2)',
            // 'rgba(75, 192, 192, 0.2)',
            // 'rgba(153, 102, 255, 0.2)',
            // 'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            '#d36866',
            'rgba(54, 162, 235, 0)',
            'rgba(255, 206, 86, 0)',
            'rgba(75, 192, 192, 0)',
            'rgba(153, 102, 255, 0)',
            '#d36866'
          ],
          borderWidth: 2
        }],



      },
      options: {
        tooltips: {
          enabled: false,
        },

        legend: {
          display: false,
        },

        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },

        animation: {
          radius: {
            duration: 1000,
            easing: 'easeOutQuad',
            from: 1,
            to: 0,
            loop: true
          }
        },

        scales: {
          xAxes: [{
            gridLines: {
              color: [
                'rgba(255, 99, 132, 0)',
                'rgba(54, 162, 235, 0)',
                'rgba(255, 206, 86, 0)',
                'rgba(75, 192, 192, 0)',
                'rgba(153, 102, 255, 0)',
                '#333745'
              ],
              lineWidth: 2,
              drawBorder: false,
              display: true,
              zeroLineWidth: 0,
              // zeroLineWidth:2,
              position: 'right',
              tickMarkLength: 1,
            },
            position: 'right',

            ticks: {
              fontFamily: "'Overpass', 'Helvetica', 'Arial', sans-serif",
              fontColor: '#333745',
              fontSize: '14',
              fontStyle: 'bold',
              autoSkip: false,
              padding: 20,
            }

          }],
          yAxes: [{
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
              drawBorder: false,
              display: true,
              zeroLineColor: '#333745',
              zeroLineWidth: 2,
              position: 'right',
              tickMarkLength: 1,
            },

            position: 'right',

            ticks: {
              fontFamily: "'Overpass', 'Helvetica', 'Arial', sans-serif",
              fontColor: '#333745',
              fontSize: '14',
              display: false,
              fontStyle: 'bold',
              autoSkip: false,
              padding: 20,
              position: 'right',
              maxTicksLimit: 5,
              beginAtZero: true,
              callback: function(value, index, values) {
                return '$' + number_format(value);
              },

              min: ticks[ticks.length - 1],
              max: ticks[0]
            },
            afterBuildTicks: function(scale) {
              scale.ticks = ticks;
              return;
            },
            beforeUpdate: function(oScale) {
              return;
            },
          }]
        }
      }
    });


    $("#total").html('$' + number_format(amounts[5]));


    $('#investment').change(function() {

      investment = $(this).val();

      var labelLength = increments + 1;
      var labels = [];
      var i = 0;

      for (i = 0; i != labelLength; i++) {

        labels.push(current_year + (i * (years / increments)))

      }

      $("#investment-label").html('$' + number_format(investment));


      var amounts = [];
      var i = 0;

      for (i = 0; i != labelLength; i++) {
        amount = investment;
        base = (1 + (interest / 1));
        exponent = (1 * (i * (years / increments)));
        compiled = Math.pow(base, exponent)
        principal = compiled * investment


        future = (monthly*12) * ((compiled - 1 )/(interest / 1))

        final = principal + future

        amounts.push(final)
      }

      $("#total").html('$' + number_format(amounts[5]));


      function updateChart() {
        myChart.data.labels = labels;
        myChart.options.scales.yAxes[0].ticks.max = Math.round(amounts[5] + amounts[2]);


        myChart.data.datasets[0].data = amounts;
        myChart.update();

        console.log(amounts[2] - amounts[0]);

      }

      updateChart();


    });

    $('#years').change(function() {

      years = $(this).val();

      var labelLength = increments + 1;
      var labels = [];
      var i = 0;

      for (i = 0; i != labelLength; i++) {

        labels.push(current_year + (i * (years / increments)))

      }


      var amounts = [];
      var i = 0;

      for (i = 0; i != labelLength; i++) {
        amount = investment;
        base = (1 + (interest / 1));
        exponent = (1 * (i * (years / increments)));
        compiled = Math.pow(base, exponent)
        principal = compiled * investment


        future = (monthly*12) * ((compiled - 1 )/(interest / 1))

        final = principal + future

        amounts.push(final)      }

      $("#years-label").html(years + ' years');
      $("#total").html('$' + number_format(amounts[5]));


      function updateChart() {
        myChart.data.labels = labels;
        myChart.options.scales.yAxes[0].ticks.max = Math.round(amounts[5] + amounts[2]);

        myChart.data.datasets[0].data = amounts;
        myChart.update();

      }

      updateChart();
      ticks = [amounts[5], 0];


    });

    $('#monthly').change(function() {

      monthly = $(this).val();

      var labelLength = increments + 1;
      var labels = [];
      var i = 0;

      for (i = 0; i != labelLength; i++) {

        labels.push(current_year + (i * (years / increments)))

      }

      $("#monthly-label").html('$' + number_format(monthly));


      var amounts = [];
      var i = 0;

      for (i = 0; i != labelLength; i++) {
        amount = investment;
        base = (1 + (interest / 1));
        exponent = (1 * (i * (years / increments)));
        compiled = Math.pow(base, exponent)
        principal = compiled * investment


        future = (monthly*12) * ((compiled - 1 )/(interest / 1))

        final = principal + future

        amounts.push(final)
      }

      $("#total").html('$' + number_format(amounts[5]));


      function updateChart() {
        myChart.data.labels = labels;
        myChart.options.scales.yAxes[0].ticks.max = Math.round(amounts[5] + amounts[2]);
        myChart.data.datasets[0].data = amounts;
        myChart.update();

      }

      updateChart();
      ticks = [amounts[5], 0];

      console.log(ticks[0]);


    });



  }



});

// var scrollElement = function (element, scrollPosition, duration) {
//   var style = element.style;
//
//   // setup CSS transition duration and easing function
//   style.webkitTransition =
//         style.transition = 5  + 's';
//   style.webkitTransitionTimingFunction =
//         style.TransitionTimingFunction = 'ease-in-out';
//
//   // use translate3d to force hardware acceleration
//   style.webkitTransform =
//         style.Transform = 'translate3d(0, ' + -2000 + 'px, 0)';
// }
//
// var scrollBody = scrollElement.bind(null,
//                                     document.getElementsByTagName('body')[0]);
//
//
//                                     function pageScroll() {
//                                         window.scrollBy(0,1);
//                                         scrolldelay = setTimeout(pageScroll,10);
//                                     }
//

// + + + + + + + + FOR SCREEN CAPTURES + + + + + + + + + + + + + + +
//
// function pagescroll() {
//
//   var $sec = $('.a-section');
//
//   var window_height = $(window).height() + 100;
//   var doc_height = $(document).height();
//   var $set_top = doc_height / count;
//   var count = Math.floor(doc_height / window_height);
//
//
//   var iteration = []
//
//   for (var i = 1; i < count; i++) {
//
//     iteration.push(window_height * i)
//
//   }
//
//   $(iteration).each(function(index, element) {
//
//     $(document).ready(function() {
//       $('body,html').delay(2000).animate({
//         scrollTop: iteration[index]
//       }, 2000, 'easeInOutQuart');
//     })
//
//
//   })
//
//   console.log(doc_height, iteration);
//
// }
//
//
// setTimeout(pagescroll,15)
//
//
//
// jQuery.easing['jswing'] = jQuery.easing['swing'];
//
// jQuery.extend( jQuery.easing,
// {
//     def: 'easeOutQuad',
//     swing: function (x, t, b, c, d) {
//         //alert(jQuery.easing.default);
//         return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
//     },
//     easeInQuad: function (x, t, b, c, d) {
//         return c*(t/=d)*t + b;
//     },
//     easeOutQuad: function (x, t, b, c, d) {
//         return -c *(t/=d)*(t-2) + b;
//     },
//     easeInOutQuad: function (x, t, b, c, d) {
//         if ((t/=d/2) < 1) return c/2*t*t + b;
//         return -c/2 * ((--t)*(t-2) - 1) + b;
//     },
//     easeInCubic: function (x, t, b, c, d) {
//         return c*(t/=d)*t*t + b;
//     },
//     easeOutCubic: function (x, t, b, c, d) {
//         return c*((t=t/d-1)*t*t + 1) + b;
//     },
//     easeInOutCubic: function (x, t, b, c, d) {
//         if ((t/=d/2) < 1) return c/2*t*t*t + b;
//         return c/2*((t-=2)*t*t + 2) + b;
//     },
//     easeInQuart: function (x, t, b, c, d) {
//         return c*(t/=d)*t*t*t + b;
//     },
//     easeOutQuart: function (x, t, b, c, d) {
//         return -c * ((t=t/d-1)*t*t*t - 1) + b;
//     },
//     easeInOutQuart: function (x, t, b, c, d) {
//         if ((t/=d/2) < 1) return c/2*t*t*t*t + b;
//         return -c/2 * ((t-=2)*t*t*t - 2) + b;
//     },
//     easeInQuint: function (x, t, b, c, d) {
//         return c*(t/=d)*t*t*t*t + b;
//     },
//     easeOutQuint: function (x, t, b, c, d) {
//         return c*((t=t/d-1)*t*t*t*t + 1) + b;
//     },
//     easeInOutQuint: function (x, t, b, c, d) {
//         if ((t/=d/2) < 1) return c/2*t*t*t*t*t + b;
//         return c/2*((t-=2)*t*t*t*t + 2) + b;
//     },
//     easeInSine: function (x, t, b, c, d) {
//         return -c * Math.cos(t/d * (Math.PI/2)) + c + b;
//     },
//     easeOutSine: function (x, t, b, c, d) {
//         return c * Math.sin(t/d * (Math.PI/2)) + b;
//     },
//     easeInOutSine: function (x, t, b, c, d) {
//         return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;
//     },
//     easeInExpo: function (x, t, b, c, d) {
//         return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b;
//     },
//     easeOutExpo: function (x, t, b, c, d) {
//         return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;
//     },
//     easeInOutExpo: function (x, t, b, c, d) {
//         if (t==0) return b;
//         if (t==d) return b+c;
//         if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
//         return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
//     },
//     easeInCirc: function (x, t, b, c, d) {
//         return -c * (Math.sqrt(1 - (t/=d)*t) - 1) + b;
//     },
//     easeOutCirc: function (x, t, b, c, d) {
//         return c * Math.sqrt(1 - (t=t/d-1)*t) + b;
//     },
//     easeInOutCirc: function (x, t, b, c, d) {
//         if ((t/=d/2) < 1) return -c/2 * (Math.sqrt(1 - t*t) - 1) + b;
//         return c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b;
//     },
//     easeInElastic: function (x, t, b, c, d) {
//         var s=1.70158;var p=0;var a=c;
//         if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
//         if (a < Math.abs(c)) { a=c; var s=p/4; }
//         else var s = p/(2*Math.PI) * Math.asin (c/a);
//         return -(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
//     },
//     easeOutElastic: function (x, t, b, c, d) {
//         var s=1.70158;var p=0;var a=c;
//         if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
//         if (a < Math.abs(c)) { a=c; var s=p/4; }
//         else var s = p/(2*Math.PI) * Math.asin (c/a);
//         return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;
//     },
//     easeInOutElastic: function (x, t, b, c, d) {
//         var s=1.70158;var p=0;var a=c;
//         if (t==0) return b;  if ((t/=d/2)==2) return b+c;  if (!p) p=d*(.3*1.5);
//         if (a < Math.abs(c)) { a=c; var s=p/4; }
//         else var s = p/(2*Math.PI) * Math.asin (c/a);
//         if (t < 1) return -.5*(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
//         return a*Math.pow(2,-10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )*.5 + c + b;
//     },
//     easeInBack: function (x, t, b, c, d, s) {
//         if (s == undefined) s = 1.70158;
//         return c*(t/=d)*t*((s+1)*t - s) + b;
//     },
//     easeOutBack: function (x, t, b, c, d, s) {
//         if (s == undefined) s = 1.70158;
//         return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;
//     },
//     easeInOutBack: function (x, t, b, c, d, s) {
//         if (s == undefined) s = 1.70158;
//         if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
//         return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
//     },
//     easeInBounce: function (x, t, b, c, d) {
//         return c - jQuery.easing.easeOutBounce (x, d-t, 0, c, d) + b;
//     },
//     easeOutBounce: function (x, t, b, c, d) {
//         if ((t/=d) < (1/2.75)) {
//             return c*(7.5625*t*t) + b;
//         } else if (t < (2/2.75)) {
//             return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
//         } else if (t < (2.5/2.75)) {
//             return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
//         } else {
//             return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
//         }
//     },
//     easeInOutBounce: function (x, t, b, c, d) {
//         if (t < d/2) return jQuery.easing.easeInBounce (x, t*2, 0, c, d) * .5 + b;
//         return jQuery.easing.easeOutBounce (x, t*2-d, 0, c, d) * .5 + c*.5 + b;
//     }
// });


// function faqs() {
//
//
//   var $faq = $('.faq')
//
//   $faq.each(function(index, element) {
//     $(this).on('click', function(e) {
//
//       $(this).toggleClass('open');
//       $(this).toggleClass('bg-' + active_color);
//       $(this).toggleClass('bg-' + bg_color);
//       $(this).toggleClass('text-' + text_color);
//       $(this).toggleClass('text-' + active_text);
//
//     });
//   });
//
// }




//
// lottie.loadAnimation({
//   container: element, // the dom element that will contain the animation
//   renderer: 'svg',
//   loop: true,
//   autoplay: true,
//   path: 'loading-logo.json' // the path to the animation json
// });
